import Vue from 'vue'
import Router from 'vue-router'
import Login from '../view/login.vue'
import LandingMain from '../view/landingMain.vue'
import Vehicles from '../view/vehicles.vue'
import Brochure from '../view/downloadBrochure.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/:country/:lang/vehicle/:modelName',
      name: 'vehicleModel',
      component: LandingMain
    },
    {
      path: '/:country/:lang',
      component: Vehicles
    },
    {
      path: '/:country/:lang/brochure',
      component: Brochure
    },
  ]
})

// Add a global beforeEach guard
router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  const currentLang = localStorage.getItem('language')
  if (lang && lang !== currentLang){
    localStorage.setItem('language', lang)
  }
  next()
})

export default router
