<template>
    <div class="modalWrapper">

      <div class="modalContainer" style="position:relative;"  >
          <div style="position:absolute;right:10px; margin-top:10px;z-index:1000000;">
            <button @click="closePopup" type="button" style="border:0;background:transparent; cursor:pointer;">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414z" fill="#0D0D0D"/></svg>
          </button>
          </div>
          <div style="position:relative;height: 750px; overflow: scroll;">
        <div class="areaheader">
          <h1>{{ langTypo === 'en' ? "Welcome to Nissan Showroom" : 'أهلاً بكم في صالة عرض نيسان'}}</h1>
          <div class="excistUser newUser">
            <h1>{{ langTypo === 'en' ? "It looks like you're new to Nissan!" : 'يبدو أنك زبون جديد لنيسان'}}</h1>
            <p>{{ langTypo === 'en' ? "Kindly provide us with some more information" : 'الرجاء تزويدنا بالمزيد من المعلومات لنتمكن من خدمتك بشكل أفضل'}}</p>
          </div>

          <form @submit.prevent="submitForm" ref="userForm"
          :class="{ 'arTypo': langTypo === 'ar' }"
          >
            <div class="form-group heroForm">
              <label for="title">{{ langTypo === 'en' ? "Title" : 'اللقب'}} </label>
              <select class="customSelect" name="title"  v-model="selectedTitle" @input="validateField('title')">
                <option disabled>{{ langTypo === 'en' ? "Select from the list" : 'أختر اللقب'}} </option>
                <option value="Mr">{{ langTypo === 'en' ? "Mr" : 'السيد'}}</option>
                <option value="Mrs">{{ langTypo === 'en' ? "Mrs" : 'السيدة'}}</option>
              </select>
              <div v-if="errorMessages.title" class="error-message">{{ errorMessages.title }}</div>
            </div>
            <div class="form-group heroForm">
              <label for="firstName">{{ langTypo === 'en' ? "First Name" : 'الإسم'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="firstName"
                name="firstName"
                placeholder=""
                v-on:focus="onfocus" v-on:blur="onblur"
                v-model="selectedFirstName"
                @input="validateField('firstName')"
              />
              <div v-if="errorMessages.firstName" class="error-message">{{ errorMessages.firstName }}</div>
            </div>
            <div class="form-group heroForm">
              <label for="lastName">{{ langTypo === 'en' ? "Last Name" : 'إسم العائلة'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="lastName"
                name="lastName"
                placeholder=""
                v-on:focus="onfocus" v-on:blur="onblur"
                v-model="selectedLastName"
                @input="validateField('lastName')"
              />
              <div v-if="errorMessages.lastName" class="error-message">{{ errorMessages.lastName }}</div>
            </div>
            <div class="form-group heroForm">
              <label for="email">{{ langTypo === 'en' ? "E-mail" : 'البريد الالكتروني'}}</label>
              <input
                type="email"
                class="heroInputArea text"
                id="email"
                name="email"
                placeholder=""
                v-on:focus="onfocus" v-on:blur="onblur"
                v-model="selectedEmail"
                @input="validateField('email')"
              />
              <div v-if="errorMessages.email" class="error-message">{{ errorMessages.email }}</div>
            </div>

            <button v-if="isLoading" class="formNextBtn" type="button" disabled>
              <div class="loader"></div>


          </button>
          <button  v-else class="formNextBtn" type="submit">{{ langTypo === 'en' ? "Save & Begin" : 'احفظ وابدأ'}}</button>

          </form>
        </div>
      </div>
    </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  export default {
    props: {
      selectedMobile: {
        type: String,
        default: ''
      },

        langTypo: String,
        countryCode: String


    },
    data() {
      return {
        isLoading: false,
        nameRegex: /^[a-zA-Z]+$/,
        emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        errorMessages: {
          title: '',
          firstName: '',
          lastName: '',
          email: '',
        },
        selectedTitle: '',
        selectedFirstName: '',
        selectedLastName: '',
        selectedEmail: '',
      };
    },
    methods: {
      closePopup(){
        // alert("asd")
        this.$emit('closePopup', "close");
      },
      onblur() {
            // e.target.placeholder = 'Enter your phone number';
            console.log("onblur")
        },
        onfocus(e) {
            // e.target.placeholder = '';
            console.log("onfocus")
        },

        validateField(fieldName) {
            const value = this.$refs.userForm[fieldName].value.trim();
            switch (fieldName) {
                case 'title':
                    this.errorMessages[fieldName] = value ? '' : 'Please select a title';
                    break;
                case 'firstName':
                this.errorMessages[fieldName] = this.nameRegex.test(value)
                        ? ''
                        : 'Please provide valid name';
                    break;
                case 'lastName':
                    this.errorMessages[fieldName] = this.nameRegex.test(value)
                        ? ''
                        : 'Please provide valid name';
                    break;
                case 'email':
                    this.errorMessages[fieldName] = this.emailRegex.test(value)
                        ? ''
                        : 'Please provide a valid email address';
                    break;
                default:
                    break;
            }
        },

      submitForm() {
        // Custom validation logic
        if (this.selectedTitle === '') {
            if(this.langTypo === 'en'){
              this.errorMessages['title'] = 'Please select a title';
          }else this.errorMessages['title'] = 'يرجى الأختيار من القائمة';
          return;
        }

        if (this.selectedFirstName === '') {
          if(this.langTypo === 'en'){
            this.errorMessages['firstName'] = 'Please enter your first name';
          }else this.errorMessages['firstName'] = 'يرجى إدخال المطلوب بدون مسافات زائدة';

          return;
        }

        if (this.selectedLastName === '') {
            if(this.langTypo === 'en'){
              this.errorMessages['lastName'] = 'Please enter your last name';
          }else this.errorMessages['lastName'] = 'يرجى إدخال المطلوب بدون مسافات زائدة';
          return;
        }

        if (this.selectedEmail === '') {
          if(this.langTypo === 'en'){
            this.errorMessages['email'] = 'Please enter your email address';
          }else this.errorMessages['email'] = 'يرجى إدخال بريد الكتروني صالح';

          return;
        }

        // console.log(this.selectedTitle);
        // console.log(this.selectedFirstName);
        // console.log(this.selectedLastName);
        // console.log(this.selectedEmail);

        // console.log('-------------');


        this.isLoading = true;

        let data = JSON.stringify([
          {
            "Title": this.selectedTitle,
            "FirstName": this.selectedFirstName,
            "LastName": this.selectedLastName,
            "Phone": this.selectedMobile,
            "Email": this.selectedEmail,
            "CustomerType": "Individual",
            "LeadSource": "Website",
            "RequestType": "Test Drive",
            "CountryOfLeadOrigin": "Dubai",
            // "Model": "Maxima",
            "SourceCategory": "SALES"
          }
        ]);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/insert',
          headers: {
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios.request(config)
        .then((response) => {
          // if()
          console.log(JSON.stringify(response.data));
          // expected output: ["00Q5E00000BbNzkUAF"]
          // response is ["00Q5E00000BbNzkUAF"] save leadID and model in localstorage
          localStorage.setItem('leadID', response.data[0]);
          // localStorage.setItem('model', );
          localStorage.setItem('name', this.selectedFirstName + ' ' + this.selectedLastName);

          this.isLoading = true;

          // this.$emit('showUserTypo', "close");
          // redirect to home page
          // this.$router.push({ path: '/' });
          // noPopup
          localStorage.setItem('noPopup', true);

          // location.href = "/";
          location.href= `/${this.countryCode}/${this.langTypo}`;


        })
        .catch((error) => {
        this.isLoading = false;

          console.log(error);
        });




        // if (form.checkValidity()) {
        //     console.log(form);
        //   // Form is valid, proceed with form submission or emit an event
        //   console.log('Form is valid. Submitting...');
        //   // Example: this.$emit('showNextComponent');
        // } else {
        //   // Form is invalid, highlight fields or show error messages
        //   console.log('Form is invalid. Please check the fields.');
        // }
      },
    },
  };
  </script>

  <style>
  .error-message {
    color: #c3002f;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
  }
  form.arTypo{
    direction: rtl;
  }
  </style>
