<template>
  <div :class="{ 'ar-lang': isArabicLang }">
    <div class="appWrapper" ref="appHeader">
      <div></div>
      <div class="areaheader" v-if="this.language === 'en'">
        <h1>Download brochure</h1>
        <p class="txtMuted">
          Explore here or get a copy of your favorite Nissan vehicle to your email.
        </p>
      </div>

      <div class="areaheader" v-else>
          <h1>تصفّح الكاتالوج</h1>
        <p class="txtMuted" dir="rtl">
          اكتشف هنا أو احصل على نسخة كاتالوج مركبتك المفضلة من نيسان في بريدك الإلكتروني.
        </p>
      </div>
    </div>

    <div style="padding:30px">
      <div class="areaheader" v-if="isLoading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div class="vehicleLineup"
      v-if="!isLoading"
      :dir="this.language === 'ar' ? 'rtl' : 'ltr'"
      :class="{ 'ar-lineup': this.language === 'ar' }"
      >
        <div
        class="item"
        v-for="(vehicle, i) in vehicleModelList"
          :key="i"


        >

              <div class="">
                <div class="vehicle-image" style="flex-basis: 59px;">
                  <a :href="'/'+countryLang+'/'+language+'/vehicle/' + vehicle.name">
                    <img :src="vehicle.image"/>
                  </a>
                </div>

                <div class="vehicle-details">
                  <div class="vehicle-link" style="min-height: 107px;">
                    <div class="header-group no-chevron">
                      <h3 class="car-title">
                        <a class="title-link">
                          {{ isArabicLang ? vehicle.name_ar : vehicle.name }}
                        </a>
                      </h3>
                    </div>

                    <div class="starting-price">
                        <div class="startingprice">
                          <span> {{ isArabicLang ? 'ابتداءً من' : 'Starting price' }}</span>

                        </div>
                        <div class="price priceBox">
                            <div class="wrapperPrice" :class="{ 'arPrice': isArabicLang }">
                                <div v-if="isArabicLang" class="wrapperPrice holder" v-html="getCurrencySymbol(vehicle.price)"></div>
                                <div class="wrapperPrice" v-else>{{ vehicle.price }}</div>
                            </div>





                          <div v-if="disclaimer !== ''" @click="toggleDisclaimer(i)" class="disclaimer"><img src="@/assets/img/disc.png" /></div>

                            <div v-if="!vehicle.id" class="disTooltip"

                            >
                              <span class="closeIcon" @click="closeDisclaimer(i)"><i>X</i></span>
                            <p>{{ disclaimer }}</p>
                          </div>

                      </div>



                    </div>
                  </div>
                  <div class="cta-section" style="min-height: 116px;"
                  v-if="language === 'en'"
                  >
                    <a class="iconNissan primary-cta" :href="vehicle.brochure_en" target="_blank"
                    >
                      <span>Explore brochure</span>
                    </a>

                    <!-- <a class="iconNissan primary-cta" :href="vehicle.cta_1_url" @click="addBtntagging(vehicle.cta_1, vehicle.cta_1_url, vehicle.name, vehicle.code)">

                    </a> -->

                    <button class="iconNissan primary-cta shareEmail" @click="handleSharePopup" :data-img="vehicle.image">Share by email</button>


                    <div v-if="showSharePopup" class="share-popup"  @click="showSharePopup = false">
                        <div class="popup-content"  @click.stop>
                          <div class="closeBtnShare" @click="showSharePopup = false">X</div>
                          <div class="headIn" style="text-align: center;
                            font-size: 24px;
                            margin-bottom: 30px; line-height: 1.3;">
                              SHARE Brochure


                            </div>
                          <div class="form-group heroForm">

                            <input v-on:focus="onfocus" v-on:blur="onblur" type="text" class="form-control heroInputArea" placeholder="E-mail" v-model="to_email">
                            <div class="form-groupWrapper" v-if="errorEmail !==''">
                            <small class="error-message" id="id">{{ errorEmail }}</small>

                          </div>

                          </div>
                          <div>
                          <button v-if="isLoadingShare"  class="primary-cta shareBtn" type="button" disabled>
                                                <div class="loader"></div>
                                            </button>
                                <button v-else  @click="shareBrochureEmail" class="primary-cta shareBtn" type="button">SEND</button>
                        </div>


                        </div>
                </div>


                <div  v-if="showSharePopupThank"  class="share-popup"  @click="showSharePopupThank = false; ">
                  <div class="popup-content thankyou" @click.stop>
                      <p>Thank you</p>

                      <p style="font-size:15px;">Brochure will be sent to your e-mail.</p>
                      <div class="shareClose" @click="showSharePopupThank = false;">X</div>
                  </div>
               </div>



                  </div>

                  <div class="cta-section" style="min-height: 116px;"
                  v-else
                  >
                    <a class="iconNissan primary-cta" :href="vehicle.brochure_ar" target="_blank"
                    >
                      <span>استكشف الكاتالوج</span>
                    </a>

                    <button class="iconNissan primary-cta shareEmail" @click="handleSharePopup" :data-img="vehicle.image">مشاركة عبر البريد الإلكتروني</button>


                    <div v-if="showSharePopup" class="share-popup"  @click="showSharePopup = false">
                        <div class="popup-content"  @click.stop>
                          <div class="closeBtnShare" @click="showSharePopup = false">X</div>
                          <div class="headIn" style="text-align: center;
                            font-size: 24px;
                            margin-bottom: 30px; line-height: 1.3;">
                              شارك الكاتالوج


                            </div>
                          <div class="form-group heroForm">

                            <input v-on:focus="onfocus" v-on:blur="onblur" type="text" class="form-control heroInputArea" placeholder="البريد الإلكتروني" v-model="to_email">
                            <div class="form-groupWrapper" v-if="errorEmail !==''">
                            <small class="error-message" id="id">{{ errorEmail }}</small>

                          </div>

                          </div>
                          <div>
                          <button v-if="isLoadingShare"  class="primary-cta shareBtn" type="button" disabled>
                                                <div class="loader"></div>
                                            </button>
                                <button v-else  @click="shareBrochureEmail" class="primary-cta shareBtn" type="button">إرسال</button>
                        </div>


                        </div>
                </div>


                <div  v-if="showSharePopupThank"  class="share-popup"  @click="showSharePopupThank = false; ">
                  <div class="popup-content thankyou" @click.stop>
                      <p>شكرًا لك</p>

                      <p style="font-size:15px;">سيتم إرسال الكاتالوج إلى بريدك الإلكتروني.</p>
                      <div class="shareClose" @click="showSharePopupThank = false;">X</div>
                  </div>
               </div>



                  </div>
                  <!-- ar-end -->




                </div>
              </div>
        </div>

        <!-- <div>{{direction}}</div> -->

        <div class="arrowWrapper" v-if="arrowWrapper">
            <div class="arrowContainer">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
            </div>
          </div>

      </div>
    </div>




  </div>

</template>

<script>
import axios from "axios";
import { checkLogin, getGradeData } from '../components/constants/functions.js';
import adobeAnalytics from "@/plugin/analytics";


export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {

  },

  data() {
    return {

      isArabicLang: false,
      language: '',
      currencySymbols: {
        "QR": "ريال",
        "KSA": "ر.س",
        "AED":"درهم"

      },
      countryLang: '',

      isLoadingShare : false,
      to_email: "",
      showSharePopup: false,
      showSharePopupThank: false,
      errorEmail: '',
      successMessage: '',

      selectedBrochure: "",
      selectedBrochureImg: "",
      selectedBrochureModel: "",

      WelcomeContact: true,
      ExcistUser: false,
      NewUser: false,
      selectedNumber: "123",

      arrowWrapper: true,
      currentBaseUrl: window.location.origin,
      colorsIn: false,
      selectedvehicleModel: null,
      threesixtyStatus: true,
      selectedColor: null,
      vehicleFoundStatus: false,
      vehicleModelList: [],
      loaded:false,
      selectedVehicleModelName: null,
      selectedVehicleId:null,
      choosedVehicle: '',
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},
      vehicles: {},


      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: 18,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: null,

      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,

      spotWrapper: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      imageColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],

      itemsPerRow: 4,
      currentRow: 0,
      isLoggedIn: false,
      v_id: 0,
      disclaimerBox: false,
      disclaimer: "",
      startY: 0,
      direction: "",



      brochure: [
        { name: "Sunny", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-NEW-SUNNY-CATALOGUE.pdf" },
        { name: "Altima", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-NEW-ALTIMA-CATALOGUE.pdf" },
        { name: "Maxima", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-MAXIMA-2020-CATALOGUE-AE.pdf" },
        { name: "Kicks", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-KICKS-2021-CATALOGUE.pdf" },
        { name: "X-Trail", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-ALLNEWXTRAIL-CATALOGUE.pdf" },
        { name: "Pathfinder", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-PATHFINDER-2022-CATALOGUE.pdf" },
        { name: "Patrol", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-PATROL-2023-CATALOGUE.pdf" },
        { name: "Patrol Nismo", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-PATROL-NISMO-UAE-CATALOGUE.pdf" },
        { name: "X-Terra", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-X-TERRA-CATALOGUE.pdf" },
        { name: "Z", link: "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-ALLNEWZ-CATALOGUE.pdf" }
      ]

    };
  },
  mounted: function () {
    this.checkLanguage();

    this.loadDisclaimerText();

      // Set the language data property when the component is mounted
      this.language = localStorage.getItem('language');

      this.countryLang = localStorage.getItem('country');



    this.$nextTick(function () {

      const login = checkLogin();
      if (login){
        this.isLoggedIn = login;
      } else {
        this.$router.push('/login');
      }


      if (this.selectedVehicleModelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;
      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 7000);


    });




  },
  updated: function () { },
  computed: {
    isArabicLanguage() {
      // Check if the language stored in localStorage is 'ar'
      return this.language === 'ar';
    },
    rows() {
      const rowCount = Math.ceil(this.vehicleModelList.length / this.itemsPerRow);
      return Array.from({ length: rowCount }, (_, index) => index);
    },
    maxRow() {
      return this.rows.length - 1;
    },
  },
  created() {
    this.getVehicles();
  },
  methods: {

    async loadDisclaimerText() {
      try {
        const response = await axios.get("/disclaimer.json");
        //console.log(response,"response");
        const countryCode = this.countryLang; // Example country code
        this.disclaimer = response.data[countryCode][this.language];
      } catch (error) {
        console.error("Error loading disclaimer text:", error);
      }
    },


    getCurrencySymbol(val) {

      for (var key in this.currencySymbols) {
        if(val){

        val = val?.replace(new RegExp(key, "g"), `<span class="wrapperPrice getCurrencySymbol">${this.currencySymbols[key]}</span>`);}
    }
    return val;
    },

    checkLanguage() {
      if (typeof localStorage !== 'undefined' && localStorage.getItem('language') === 'ar') {
        this.isArabicLang = true;
      }
    },

    handleSharePopup(event){


      this.showSharePopup = true

      const closestAnchor = event.target.closest('div.vehicle-details .cta-section').querySelector('a');
      let carTitleElement = event.target.closest('div.vehicle-details').querySelector('.car-title a.title-link');
      let carImgElement = event.target.getAttribute('data-img');




      if (closestAnchor) {
          const hrefLink = closestAnchor.getAttribute('href');
          let carTitleText = carTitleElement.textContent.trim();

          this.selectedBrochure = hrefLink;
          this.selectedBrochureModel = carTitleText;
          this.selectedBrochureImg = carImgElement;

      } else {
          console.log('No closest anchor tag found.');
      }

    },

    validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
      onblur() {
            // e.target.placeholder = 'Enter your phone number';
            console.log("onblur")
        },
        onfocus() {
            // e.target.placeholder = '';
            console.log("onfocus")
        },

    getBrochureLink(vehicleName) {
      const brochureItem = this.brochure.find(item => item.name.toLowerCase() == vehicleName.toLowerCase()) || { name: "", link: "" };
      return brochureItem.link != "" ? brochureItem.link : '#';
    },

    shareBrochureEmail() {
      let email = this.to_email;
      if (email === '') {
        this.errorEmail = 'Please enter your email address';
        return;
      } else if (!this.validateEmail(email)) {
        this.errorEmail = 'Please enter a valid email address';
        return;
      } else {
        console.log("Valid Email");
        this.errorEmail = '';
      }



      const formData = new FormData();
      formData.append('to_email', this.to_email);
      formData.append('download_link', this.selectedBrochure);
      formData.append('image', this.selectedBrochureImg);
      formData.append('model_name', this.selectedBrochureModel);
      formData.append('lang', localStorage.getItem("language"));

      this.successMessage = '';
      this.errorEmail = '';
      this.isLoadingShare = true;
      axios.post('https://showroom.nissan-middleeast.com/backend/public/api/v1/send-borchure', formData)
          .then(response => {
           // console.log('Response:', response);
            //console.log(response.data.success)
            if (response.data.success) {
              this.errorEmail = '';
              this.successMessage = response.data.success;
              this.showSharePopup =false;
              this.showSharePopupThank = true;
              this.to_email =""
            } else {
              this.successMessage = '';
              this.errorEmail = response.data.message;
            }
            this.isLoadingShare = false;
          })
          .catch(error => {
            this.isLoadingShare = false;
            console.error('Error:', error);
            this.errorEmail = "Something went wrong. Try again";
          });

    },


    logout(){
      localStorage.removeItem('noPopup');
      localStorage.removeItem('name');
      localStorage.removeItem('leadID');
      localStorage.removeItem('model');

      location.href= "/"

    },
    closePopup(param){

      if(param == "close"){
        // alert()
        this.ExcistUser = false;
        this.WelcomeContact = false
        localStorage.setItem('noPopup', true);
      localStorage.removeItem('name');
      localStorage.removeItem('leadID');
      localStorage.removeItem('model');

      }
    },


    toggleDisclaimer(i){

      this.vehicleModelList[i].id = !this.vehicleModelList[i].id;


    },
    closeDisclaimer(i){
      this.vehicleModelList[i].id = !this.vehicleModelList[i].id;
    },
    addBtntagging(selectedCta, url, vehicle_name, vehicleid) {
      let event_happened = selectedCta;

      let event_126 = "";
      let events = '';

      let eVar14 = event_happened + "|" + url

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
    },
    toggleColorBox() {
      if (this.colorsIn == true) {
        this.colorsIn = false;
      } else{
        this.colorsIn = true;
      }
    },

    checkLocal(){
      if(localStorage.getItem('noPopup') == null){
        return true
      }else{
        return false
      }
    },

    async getVehicles() {
      this.isLoading = true;
      const country_code = localStorage.getItem("country") ?? "qa"
      try {
        console.log(country_code,"country_code");
        const result = await axios.get(this.$apibaseurl + country_code+"/vehicles");
        this.vehicleModelList = result.data.data;

        let event_happened = "Home";
        let event_126 = "";
        let events = '';
        let eVar14 = event_happened + "|" + this.currentBaseUrl
        adobeAnalytics(
        "R",
        "",
        "",
        "",
        "",
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },

    async getVehicleModelData(vehicleModelName) {
      const vehicleModelData = {};
      try {
        const result = await axios.get(this.$apibaseurl + "api/model/" + vehicleModelName);

        if (result.data.grades.length > 0) {
          const colorCode = 0;
          const gradeDataResponse = getGradeData(result.data.grades[0], 0, colorCode, 0, 0);
          for (const key in gradeDataResponse) {
            if (gradeDataResponse.hasOwnProperty(key)) {
              vehicleModelData[key] = gradeDataResponse[key];
            }
          }
          vehicleModelData.threesixtyStatus = true;
          vehicleModelData.vehicleFoundStatus = true;
        }
        return vehicleModelData;

      } catch (error) {
        console.log(error)
        return error;
      }
    },

    getRowVehicles(row) {
      const startIndex = row * this.itemsPerRow;
      const endIndex = startIndex + this.itemsPerRow;
      const rowVehicles = this.vehicleModelList.slice(startIndex, endIndex);

      let id = endIndex+1;
      while (rowVehicles.length < this.itemsPerRow) {
        rowVehicles.push({ id: id++ });
      }

      return this.vehicleModelList;
    },

    prevRow() {
      if (this.currentRow > 0) {
        this.currentRow--;
      }
    },

    nextRow() {
      if (this.currentRow < this.maxRow) {
        this.currentRow++;
      }
    },
  },

  watch: {},
};

</script>


<style>

.vueperslides__bullets--outside {
  margin-top: -50px !important;
}

.vueperslides__arrow svg {
  width: 5em;
}
.vueperslides__arrows{
  opacity: 0.5;
}
.vueperslides__arrow{
  transform: none;
  top:-250px;
}
.vehicle-details{
  padding-left: 50px;
}
.vehicle-link{
  margin-top: 0px;
}
.price{
  margin: 0;
  font: 70px/90px "Nissan Light","Nissan Regular",Verdana,Arial,sans-serif;
}

.starting-price{
  margin-bottom: 15px;
}

.content {
  -webkit-transition: all,1.2s,ease-out;
  -moz-transition: all,1.2s,ease-out;
  transition: all,1.2s,ease-out;
  visibility: visible;
  max-height: 500px;
  opacity: 1;
}

 img {
  border: 0;
  width: 720px;
  /* max-width: 100%; */
}

.primary-cta {
  padding: 40px 0;
  display: block;
  margin: 0;

  border: 0;
  text-decoration: none;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
}

.primary-cta:after {
  font-size: 2rem;
  content: "";
  color: #000;
  top: 50%;
  overflow: hidden;
  position: relative;
  padding-left: 10px;
  padding-top:30px;
  right: 0;
  margin-top: 0;
  display: inline-block;
  display: none;
}

.car-title, .car-title a {
    color: #000;
    text-decoration: none;
    font-size: 60px;
    padding-bottom: 25px;
}

.car-title {
    text-transform: uppercase;
    margin: 0 0 5px;
}

span {
  font-size: 45px;
}

.startingprice span{
  font-size: 40px;
}


.image-row-relative {
  position: relative;
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 100px;
  background-color: #000;
  color: white;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-buttons.left {
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.navigation-buttons.right {
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.navigation-buttons i {
  line-height: 80px;
  font-size: 24px;
}

.image-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.image-block {
  flex: 1;
  margin: 10px;
}

.custom-div {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}

.areaheader {
  text-align: center;
  margin-bottom: 6.5%;
  margin-top: 6.5%;
}

.areaheader label {
  font-weight: bold;
  font-size: 3em;
}

.areaheader h1 {
  font-weight: lighter;
  font-size: 100px;
}

.disabled {
  background-color: whitesmoke;
  cursor: not-allowed;
}


.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}

@media (min-height: 810px) {
  .panIcon .panning img{
    bottom: -55px;
  }
}

.loading{
  width: 100%;
  display: flex;

}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  margin: auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ar-lineup .wrapperPrice{
  font-weight: 300;
  font-size: 20px;
}
.ar-lineup .cta-section .primary-cta{
  letter-spacing: 0;
}
</style>
