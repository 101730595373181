<template>
  <div v-if="this.CarouselColor.length !== undefined">
    <VueSlickCarousel
      :key="carouselKey"
      v-bind="settings"
      class="colorsTray fade-in-bottom aniDelay-2s"
      ref="colorsTray"
    >

      <li
        v-for="(imgColour, index) in this.CarouselColor"
        :key="index"
        :class="{ active: index === isActiveColor }"
        @click="changeCarColour(imgColour.colour_code, index)"
      >
        <img :src="$apibaseurl + imgColour.colour.image" />
        <span
          class="tooltipColor"
          :data-tooltipcolor="imgColour.colour.name"
          data-tooltip-pos="up"
          data-tooltip-length="medium"
        ></span>
      </li>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "CarouselColor",
  components: { VueSlickCarousel },

  data() {
    return {
      settings: {
        arrows: true,
        mobileFirst: true,
        dots: false,
        slidesToShow: 5,
        rows: 1,
        touchThreshold: 5,
        infinite: false,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,

            }
          }
        ]
      },

      isActiveColor: 0,
      valueDef: 0,
      carouselKey: "",
    };
  },

  props: {
    CarouselColor: "",
  },
  created() {},
  watch: {
    services() {
      this.forceReload();
    },
  },

  methods: {
    changeCarColour(colour_code, index) {
      this.$emit("getColourCode", colour_code, index);
      this.isActiveColor = index;
    },
    setValue(valueDef) {
      this.isActiveColor = valueDef;
      this.forceReload();
    },

    forceReload() {
      this.carouselKey += 1;
    },
  },
};
</script>