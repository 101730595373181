<template>
  <div class="modal" v-if="isBookNowModalVisible">
    <div class="modal-content">
      <span @click="hideModal" class="close"><i class="fas fa-times" style="color: black;"></i></span>


      <div v-if="successMsg">
        <div class="content-container">
          <div class="center-section">
            <div>
              <div class="form-group success">
                Thank you
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!successMsg">
        <div class="content-container">
          <div class="center-section">
            <div>
              <div class="form-group">
                <label class="red-asterisk" for="test_drive_type">Test Drive Type</label>
                <div class="booknow-select">
                  <select class="booknowselect" id="test_drive_type" name="testDriveType" ref="testDriveType" @change="clearError('errorDriveType')" required>
                    <option selected="selected" disabled="disabled" value="">Please select</option>
                    <option value="HOME_TEST_DRIVE_INQUIRY">Home Test Drive</option>
                    <option value="BOOK_A_TEST_DRIVE">Showroom Test Drive</option>
                  </select>
                </div>
                <div class="form-group" v-if="errorDriveType !==''">
                  <small class="error">{{ errorDriveType }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="preferred_model">Preferred Model</label>
                <div class="booknow-select">
                  <select class="booknowselect" id="preferred_model" name="preferredModel" ref="preferredModel" @change="clearError('errorPreferredModel')" required>
                    <option selected="" disabled="disabled" value="">Please select</option>
                    <option v-for="model in modelList" :key="model.id" :value="model.code">{{ model.name }}</option>
                  </select>
                </div>
                <div class="form-group" v-if="errorPreferredModel !==''">
                  <small class="error">{{ errorPreferredModel }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="title">Title</label>
                <div class="booknow-select">
                  <select class="booknowselect" id="title" name="title" ref="title" @change="clearError('errorTitle')" required>
                    <option selected="selected" disabled="disabled" value="">Please select</option>
                    <option value="mr.">Mr.</option>
                    <option value="mrs.">Mrs.</option>
                    <option value="ms.">Ms.</option>
                  </select>
                </div>
                <div class="form-group" v-if="errorTitle !==''">
                  <small class="error">{{ errorTitle }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="first_name">First Name</label>
                <div class="first_name">
                  <input id="first_name" class="input" type="text" name="firstName"  ref="firstName" @change="clearError('errorFname')" required>
                </div>
                <div class="form-group" v-if="errorFname !==''">
                  <small class="error">{{ errorFname }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="last_name">Last Name</label>
                <div class="last_name">
                  <input id="last_name" class="input" type="text" name="lastName" ref="lastName" @change="clearError('errorLname')" required>
                </div>
                <div class="form-group" v-if="errorLname !==''">
                  <small class="error">{{ errorLname }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="email">Email</label>
                <div class="email">
                  <input id="email" class="input" type="email" name="email" ref="email" @change="clearError('errorEmail')" required>
                </div>
                <div class="form-group" v-if="errorEmail !==''">
                  <small class="error">{{ errorEmail }}</small>
                </div>
              </div>

              <div class="form-group">
                <label class="red-asterisk" for="mobile_no">Mobile No.</label>
                <div class="mobile_no">
                  <input id="mobile_no" class="input" type="text" name="mobileNo" ref="mobileNo" @change="clearError('errorMobile')" required>
                </div>
                <div class="form-group" v-if="errorMobile !==''">
                  <small class="error">{{ errorMobile }}</small>
                </div>
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <div class="description">
                  <input id="description" class="input" type="text" name="description" ref="description">
                </div>
              </div>

              <div class="form-group">
                <label for="comments">Comments</label>
                <div class="comments">
                  <textarea id="comments" class="input" name="comments" ref="comments"></textarea>
                </div>
              </div>

              <div class="form-group">
                <label for="all_opt_in" class="checkbox-label">
                  <input id="all_opt_in" class="checkbox" type="checkbox" name="allOptIn" ref="allOptIn" checked>
                  I'D LIKE TO RECEIVE MARKETING COMMUNICATION FROM NISSAN
                </label>
              </div>

              <div class="form-group" v-if="errorMsg !==''">
                <small class="error">{{ errorMsg }}</small>
              </div>

              <div class="form-group">
                <div class="" style="background-color: black;">
                  <button class="custom-button iconNissan primarycta" @click="handleSubmit">Send</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    props: {
      isBookNowModalVisible: Boolean,
      modelList: Array,
    },
    data() {
      return {
        errorMsg: '',
        errorDriveType: '',
        errorPreferredModel: '',
        errorTitle: '',
        errorFname: '',
        errorLname: '',
        errorEmail: '',
        errorMobile: '',
        successMsg:false,
      };
    },
    methods: {
      hideModal() {
        this.successMsg = false;
        this.$emit("close");
      },

      clearError(fieldName) {
        this[fieldName] = '';
      },
      resetForm() {
        this.$refs.testDriveType.value = '';
        this.$refs.preferredModel.value = '';
        this.$refs.title.value = '';
        this.$refs.firstName.value = '';
        this.$refs.lastName.value = '';
        this.$refs.email.value = '';
        this.$refs.mobileNo.value = '';
        this.$refs.description.value = '';
        this.$refs.comments.value = '';
        this.$refs.allOptIn.checked = false;

        this.errorDriveType = '';
        this.errorPreferredModel = '';
        this.errorTitle = '';
        this.errorFname = '';
        this.errorLname = '';
        this.errorEmail = '';
        this.errorMobile = '';
        this.errorMsg = '';
        this.successMsg = false;
      },
      handleSubmit() {
        this.successMsg = false;
        this.errorDriveType = '';
        this.errorPreferredModel = '';
        this.errorTitle = '';
        this.errorFname = '';
        this.errorLname = '';
        this.errorEmail = '';
        this.errorMobile = '';
        this.errorMsg = '';

        const testDriveType = this.$refs.testDriveType.value;
        if (testDriveType === '') {
          this.errorDriveType = 'Please select a Drive Type';
          return;
        }

        const preferredModel = this.$refs.preferredModel.value;
        if (preferredModel === '') {
          this.errorPreferredModel = 'Please select a Preferred Model';
          return;
        }

        const title = this.$refs.title.value;
        if (title === '') {
          this.errorTitle = 'Please select a title';
          return;
        }

        const firstName = this.$refs.firstName.value;
        if (firstName === '') {
          this.errorFname = 'Please enter your first name';
          return;
        } else if (!this.validateName(firstName)) {
          this.errorFname = 'First name only allowed letters and spaces';
          return;
        }

        const lastName = this.$refs.lastName.value;
        if (lastName === '') {
          this.errorLname = 'Please enter your last name';
          return;
        } else if (!this.validateName(lastName)) {
          this.errorLname = 'Last name only allowed letters and spaces';
          return;
        }

        const email = this.$refs.email.value;
        if (email === '') {
          this.errorEmail = 'Please enter your email address';
          return;
        } else if (!this.validateEmail(email)) {
          this.errorEmail = 'Please enter a valid email address';
          return;
        }

        const mobileNo = this.$refs.mobileNo.value;
        if (mobileNo === '') {
          this.errorMobile = 'Please enter your mobile number with leading 0';
          return;
        } else if (!this.validateMobileNumber(mobileNo)) {
          this.errorMobile = 'Please enter a valid mobile number';
          return;
        }

        const description = this.$refs.description.value;
        const comments = this.$refs.comments.value;
        const allOptIn = this.$refs.allOptIn.checked;

        const formData = new FormData();
        formData.append('formType', testDriveType);
        formData.append('vehicle', preferredModel);
        formData.append('Title', title);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('phone', mobileNo);
        formData.append('description', description);
        formData.append('comments', comments);
        formData.append('allOptIn', allOptIn);

        formData.append('CampaignCode', null);
        formData.append('HeliosLeadId', null);
        formData.append('requestOrigin', null);
        formData.append('customerType', null);
        formData.append('countryOfLeadOrigin', null);
        formData.append('prospectStatus', null);
        formData.append('language', null);
        formData.append('trackingId', null);


        axios.post('https://showroom.assetrepository.me/submit.php', formData)
          .then(response => {
            console.log('Response:', response.data);
            this.successMsg = true;
            console.log(response.data.success)
            if (response.data.success) {
              this.resetForm();
            }
          })
          .catch(error => {
            console.error('Error:', error);
            this.errorMsg = "Something went wrong. Try again";
          });

          this.errorDriveType = '';
          this.errorPreferredModel = '';
          this.errorTitle = '';
          this.errorFname = '';
          this.errorLname = '';
          this.errorEmail = '';
          this.errorMobile = '';
          this.errorMsg = '';
      },
      validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
      validateMobileNumber(mobileNo) {
        const mobileRegex = /^05(0|4|6|5|2|8)\d{7}$/;
        return mobileRegex.test(mobileNo);
      },
      validateName(value) {
        const lettersWithSpaceRegex = /^[A-Za-z ]+$/;
        return lettersWithSpaceRegex.test(value);
      },
    },
  };
</script>

<style>
  .red-asterisk::after {
    content: " *";
    color: red;
  }
  .success {
    align-items: center;
    text-align: center;
    color: green;
    font-size: 30px;
  }
  .error {
    color: red;
  }
  .iconNissan::before {
    font-family: icon-Nissan-Global;
  }
  .custom-button {
    font-family: "Nissan Regular";
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    height: 3em;
    overflow: hidden;
    margin-right: 1.2em;
  }

  .primarycta::before {
    font-size: .7857142857rem;
    content: "";
    color: #fff !important;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 30px;
    display: inline-block;
  }

  .content-container {
    display: flex;
    justify-content: center;
  }

  .booknowselect {
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    flex: 1;
    padding: 0 1em;
    color: #000;
    background-color: #fff;
    background-image: none;
    cursor: pointer;
    text-transform: uppercase;
    /* border-radius: 40px; */
  }

  .booknow-select {
      position: relative;
      display: flex;
      width:100%;
      height: 3em;
      overflow: hidden;
      margin-right: 1.2em;
      border: 1px solid #000;
  }

  .booknow-select::after {
      content: '';
      position: absolute;
      top: 6px;
      right: 0;
      background-color: transparent;
      transition: .25s all ease;
      pointer-events: none;
      height: 50%;
      width: 33px;
      padding: 3px 0;
      border-left: 1px solid #000;
      background-image: url(../../assets/img/angle_down_icon_black.png);
      background-size: 25px;
      background-position: center center;
      background-repeat: no-repeat;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    background-color: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .center-section{
    text-align: left;
    width: 90%;
  }

  .input {
    position: relative;
    display: flex;
    width:100%;
    height: 3em;
    overflow: hidden;
    margin-right: 1.2em;
  }

  .checkbox {
    position: relative;
    display: inline-flex;
    width: 1em;
    height: 1em;
    overflow: hidden;
    margin-right: 0.3em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .checkbox-label {
    font-size: 14px !important;
    width: 250px;
  }

  .form-group {
    padding-bottom: 0px;
  }

  .all_opt_in {
    display: flex;
    align-items: left;
    justify-content: flex-start;
  }

  .all_opt_in input[type="checkbox"] {
    margin-right: 10px;
  }
</style>
