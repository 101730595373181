<template>
  <div class="modalWrapper">
    <div class="modalContainer">
      <div class="areaheader">
        <h1>{{ lang == 'en' ? "Welcome to Nissan Showroom" : 'أهلاً بكم في صالة عرض نيسان'}}</h1>
          <div class="excistUser">
              <h3 v-if="lang == 'en'">Welcome back <span>{{name}}</span>!</h3>
              <h3 v-else class="arRtl">مرحبًا بك مرة أخرى <span>{{name}}</span>!</h3>

           <p class="" v-if="lang == 'en'"> Are you still interested in {{ model}}?</p>
           <p class="arRtl" v-else> هل لا تزال مهتمًا بـ {{ model}}?</p>
           <img :src="vehicleModel.image" class="interestImg"/>

           <div class="ctaWrapper" v-if="lang === 'en'">
            <button @click="redirectModel()" type="button" class="modalCta"><span>Discover {{ model}}</span></button>
            <button @click="redirectHome()" type="button"  class="modalCta"><span>Discover all vehicles</span></button>
           </div>
           <div class="ctaWrapper arLang" v-else>
            <button @click="redirectModel()" type="button" class="modalCta"><span>إكتشف {{ model}}</span></button>
            <button @click="redirectHome()" type="button"  class="modalCta"><span>إكتشف سيارات</span></button>
           </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {

  data(){
    return {
      model: 'Patrol',
      name: 'John',
      vehicleModel: [],
      lang: "",
      country: "",

      langTypo: {
      type: String, // Specify the type of the prop
      default: 'en' // Set a default value if needed
    },
      countryCode: String

    }
  },

  // read model and name from localstorage
  async mounted(){

    const loclang = localStorage.getItem('language');
    this.lang = loclang;

    const loccountry = localStorage.getItem('country');
    this.country = loccountry;


    this.model = localStorage.getItem('model');
    this.name = localStorage.getItem('name');
    const country_code = localStorage.getItem("country");
    //const result = await axios.get(this.$apibaseurl + "api/v1/az/vehicles");
    const result = await axios.get(this.$apibaseurl + country_code+"/vehicles");
    const vehicleModelList = result.data.data;
    // filter vehicleModelList to get the model name
    this.vehicleModel = vehicleModelList.filter((vehicle) => {
      // console.log(vehicle.name, this.model);
      return vehicle.name === this.model;
    })[0];

  },
  methods: {
    redirectModel(){
      localStorage.setItem('noPopup', true);

      location.href= `/${this.country}/${this.lang}/vehicle/` + this.model;
      //location.href= `/vehicle/` + this.model;
    },
    redirectHome(){
      localStorage.setItem('noPopup', true);
      location.href= `/${this.country}/${this.lang}`
      // return "/";
    }
  },

  // props: {
  //   model: {
  //     type: String,
  //     default: 'Patrol'
  //   },
  //   name:{
  //     type: String,
  //     default: 'John'
  //   }
  // },
};


</script>

<style>
.arRtl{
  direction: rtl;
}
.ctaWrapper.arLang{
  flex-direction: row-reverse !important;
}
.ctaWrapper.arLang button span{
  direction: rtl;
}
.ctaWrapper.arLang button{
  margin-left: 2.5em !important;
  margin-right: 0 !important;
}
.ctaWrapper.arLang button:nth-last-child(1){
  margin-left: 0;
}
</style>