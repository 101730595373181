<template>
    <div class="modalWrapper">
        <div class="modalContainer"
        :class="{ 'arTypo': langTypo === 'ar' }"
        >
        <button
        class="closeBtn"
        @click="emitEvent"
        >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.06 12L17.48 7.57996C17.5537 7.5113 17.6128 7.4285 17.6538 7.3365C17.6948 7.2445 17.7168 7.14518 17.7186 7.04448C17.7204 6.94378 17.7018 6.84375 17.6641 6.75036C17.6264 6.65697 17.5703 6.57214 17.499 6.50092C17.4278 6.4297 17.343 6.37356 17.2496 6.33584C17.1562 6.29811 17.0562 6.27959 16.9555 6.28137C16.8548 6.28314 16.7555 6.30519 16.6635 6.34618C16.5715 6.38717 16.4887 6.44627 16.42 6.51996L12 10.94L7.58 6.51996C7.43782 6.38748 7.24978 6.31535 7.05548 6.31878C6.86118 6.32221 6.67579 6.40092 6.53838 6.53834C6.40096 6.67575 6.32225 6.86113 6.31882 7.05544C6.3154 7.24974 6.38752 7.43778 6.52 7.57996L10.94 12L6.52 16.42C6.37955 16.5606 6.30066 16.7512 6.30066 16.95C6.30066 17.1487 6.37955 17.3393 6.52 17.48C6.66062 17.6204 6.85125 17.6993 7.05 17.6993C7.24875 17.6993 7.43937 17.6204 7.58 17.48L12 13.06L16.42 17.48C16.5606 17.6204 16.7512 17.6993 16.95 17.6993C17.1488 17.6993 17.3394 17.6204 17.48 17.48C17.6204 17.3393 17.6993 17.1487 17.6993 16.95C17.6993 16.7512 17.6204 16.5606 17.48 16.42L13.06 12Z" fill="#000000"></path> </g></svg>
        </button>
                <form>
                    <div class="formWrapper">
                    <div class="form-group heroForm">
              <label for="LMS_CarModel__c">{{ langTypo === 'en' ? "Preferred Model" : 'الموديل المرغوب'}} </label>
              <select class="customSelect" name="LMS_CarModel__c" >
                <option disabled selected>{{ langTypo === 'en' ? "Select from the list" : 'أختر اللقب'}} </option>
                <option value="Y62">{{ langTypo === 'en' ? "Patrol" : 'باترول نيسمو'}}</option>
                <option value="Y62N">{{ langTypo === 'en' ? "Patrol Nismo" : 'باترول نيسمو'}}</option>
                <option value="T33">{{ langTypo === 'en' ? "X-Trail" : 'أكس-تريل'}}</option>
                <option value="P60A">{{ langTypo === 'en' ? "X-Terra" : 'اكس-تيرا'}}</option>
                <option value="R53">{{ langTypo === 'en' ? "Pathfinder" : 'باثفايندر'}}</option>
                <option value="L34">{{ langTypo === 'en' ? "Altima" : 'ألتيما'}}</option>
                <option value="N18">{{ langTypo === 'en' ? "Sunny" : 'صني'}}</option>
                <option value="P15">{{ langTypo === 'en' ? "Kicks" : 'Kicks'}}</option>
                <option value="A36">{{ langTypo === 'en' ? "Maxima" : 'ماكسيما'}}</option>
                <option value="Z34">{{ langTypo === 'en' ? "Nissan Z" : 'نيسان Z'}}</option>


              </select>
              <!-- <div v-if="errorMessages.title" class="error-message">{{ errorMessages.title }}</div> -->
            </div>

            <div class="form-group heroForm">
              <label for="Payment_Type__c">{{ langTypo === 'en' ? "Payment Type" : 'طريقة الدفع'}} </label>
              <select class="customSelect" name="Payment_Type__c" >
                <option disabled selected>{{ langTypo === 'en' ? "Select from the list" : 'أختر اللقب'}} </option>
                <option value="Cash">{{ langTypo === 'en' ? "Cash" : 'نقدي'}}</option>
                <option value="Installment">{{ langTypo === 'en' ? "Installment" : 'قسط شهري'}}</option>
              </select>
              <!-- <div v-if="errorMessages.title" class="error-message">{{ errorMessages.title }}</div> -->
            </div>


            <div class="form-group heroForm">
              <label for="LMS_PurchaseHorizon__c">{{ langTypo === 'en' ? "Purchase Horizon" : 'احتمال الشراء'}} </label>
              <select class="customSelect" name="LMS_PurchaseHorizon__c" >
                <option disabled selected>{{ langTypo === 'en' ? "Select from the list" : 'أختر اللقب'}} </option>
                <option value="Within 1 Month">{{ langTypo === 'en' ? "Within 1 Month" : 'خلال ٣٠ يوماً'}}</option>
                <option value="From 1 to 3 Months">{{ langTypo === 'en' ? "From 1 to 3 Months" : 'من ١ إلى ٣ أشهر'}}</option>
                <option value="More Than 3 Months">{{ langTypo === 'en' ? "More Than 3 Months" : 'من ٣ إلى ٦ أشهر'}}</option>
              </select>
              <!-- <div v-if="errorMessages.title" class="error-message">{{ errorMessages.title }}</div> -->
            </div>

            <div class="form-group heroForm">
              <label for="City">{{ langTypo === 'en' ? "City" : 'المدينة'}} </label>
              <select class="customSelect" name="City" >
                <option disabled selected>{{ langTypo === 'en' ? "Select from the list" : 'أختر اللقب'}} </option>
                <option value="Riyadh">{{ langTypo === 'en' ? "Riyadh" : 'الرياض'}}</option>
                <option value="Jeddah">{{ langTypo === 'en' ? "Jeddah" : 'جدة'}}</option>
                <option value="Dammam">{{ langTypo === 'en' ? "Dammam" : 'الدمام'}}</option>
                <option value="Assir">{{ langTypo === 'en' ? "Assir" : 'عسير'}}</option>
                <option value="Makkah">{{ langTypo === 'en' ? "Makkah" : 'مكة'}}</option>
                <option value="Khubar">{{ langTypo === 'en' ? "Khubar" : 'الخبر'}}</option>
                <option value="Jizan">{{ langTypo === 'en' ? "Jizan" : 'جيزان'}}</option>
                <option value="Jubail">{{ langTypo === 'en' ? "Jubail" : 'الجبيل'}}</option>
                <option value="Ahsa">{{ langTypo === 'en' ? "Ahsa" : 'الأحساء'}}</option>
                <option value="Taif">{{ langTypo === 'en' ? "Taif" : 'الطائف'}}</option>
                <option value="Tabuk">{{ langTypo === 'en' ? "Tabuk" : 'تبوك'}}</option>
                <option value="Hail">{{ langTypo === 'en' ? "Hail" : 'حائل'}}</option>
                <option value="Baha">{{ langTypo === 'en' ? "Baha" : 'الباحة'}}</option>
                <option value="Yanbu">{{ langTypo === 'en' ? "Yanbu" : 'ينبع'}}</option>
                <option value="Jawf">{{ langTypo === 'en' ? "Jawf" : 'الجوف'}}</option>
                <option value="Al Qassim">{{ langTypo === 'en' ? "Al Qassim" : 'القسيم'}}</option>
                <option value="Al Madina">{{ langTypo === 'en' ? "Al Madina" : 'المدينة المنورة'}}</option>
                <option value="Najran">{{ langTypo === 'en' ? "Najran" : 'نجران'}}</option>
                <option value="Khafji">{{ langTypo === 'en' ? "Khafji" : 'الخفج'}}</option>
                <option value="Al Qateef">{{ langTypo === 'en' ? "Al Qateef" : 'القطيف'}}</option>
                <option value="Saihat">{{ langTypo === 'en' ? "Saihat" : 'سيهات'}}</option>
                <option value="Buraydah">{{ langTypo === 'en' ? "Buraydah" : 'بريدة'}}</option>
                <option value="Hafr Al Batin">{{ langTypo === 'en' ? "Hafr Al Batin" : 'حفر الباطن'}}</option>
                <option value="Al Kharj">{{ langTypo === 'en' ? "Al Kharj" : 'الخرج'}}</option>
                <option value="Arar">{{ langTypo === 'en' ? "Arar" : 'عرعر'}}</option>
                <option value="Sakaka">{{ langTypo === 'en' ? "Sakaka" : 'سكاكا'}}</option>
                <option value="Ar Rass">{{ langTypo === 'en' ? "Ar Rass" : 'الرس'}}</option>




              </select>
              <!-- <div v-if="errorMessages.title" class="error-message">{{ errorMessages.title }}</div> -->
            </div>

            <div class="form-group heroForm">
              <label for="FirstName">{{ langTypo === 'en' ? "First Name" : 'الإسم'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="FirstName"
                name="FirstName"

              />
            </div>

            <div class="form-group heroForm">
              <label for="LastName">{{ langTypo === 'en' ? "Last Name" : 'إسم العائلة'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="LastName"
                name="LastName"

              />
            </div>

            <div class="form-group heroForm">
              <label for="Phone">{{ langTypo === 'en' ? "Contact Number" : 'رقم الجوال'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="Phone"
                name="Phone"

              />
            </div>

            <div class="form-group heroForm">
              <label for="Email">{{ langTypo === 'en' ? "E-mail" : 'البريد الالكتروني'}}</label>
              <input
                type="text"
                class="heroInputArea text"
                id="Email"
                name="Email"

              />
            </div>

        </div>

            <div class="form-group checkboxHero heroForm">
                <input type="checkbox" id="CommunicationOptIn__c"
                name="CommunicationOptIn__c">
                <label for="CommunicationOptIn__c">{{ langTypo === 'en' ? "I'D LIKE TO RECEIVE MARKETING COMMUNICATION FROM NISSAN" : ' أرغب بتلقي أحدث العروض المتوفرة من نيسان'}}</label>
            </div>




            <input type="hidden" id="Country__c"
                name="Country__c">
                <input type="hidden" id="Language__c"
                name="Language__c">
                <input type="hidden" id="Company"
                name="Company">
                <input type="hidden" id="FormName__c"
                name="FormName__c">
                <input type="hidden" id="LeadSource"
                name="LeadSource">
                <input type="hidden" id="Status"
                name="Status">
                <input type="hidden" id="DealerName__c"
                name="DealerName__c">

                <button type="submit" class="heroBlackBtn register">{{ langTypo === 'en' ? "submit" : 'إرسال'}}</button>

                </form>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    // emits: ['showNextComponent'],
    data(){
        return {

        }
    },
    props: {
        langTypo: String,
        countryCode: String
  },
  computed: {

    },
    // onmounted clear localstorage
    mounted(){
        this.mobile = '';
        // localStorage.clear();
        console.log(this.langTypo,"langTypo");
    },
    methods: {
        emitEvent() {
        this.$emit('handleRegister', '');
    },
        updateInput(e) {
            if (this.validate && this.countryPatterns[this.countryCode.toUpperCase()]) {
                if (e.target.value.match(this.countryPatterns[this.countryCode.toUpperCase()])) {
                    this.error = false;
                    console.log("true");
                } else {
                    this.error = true;
                    console.log("false");
                }
            }


        },
        onblur() {
            // e.target.placeholder = 'Enter your phone number';
            console.log("onblur")
        },
        onfocus(e) {
            // e.target.placeholder = '';
            console.log("onfocus")
        },
      async  checkUser(e) {
            if (this.mobile.trim()) {

                if (!this.mobile.match(this.countryPatterns[this.countryCode.toUpperCase()])) {
                this.error = true;
                this.validate = true;
                return 0;
            }

                this.loading = true
                const result = axios.get(this.$apibaseurl + "salesforce/search?phone="+this.mobile+"&random="+Math.ceil(Math.random()*1000000)).then((response) => {
                    console.log(response.data);

                    // if resppnse cpde 200
                    console.log("response.", response)

                    if(response.status == 200){
                        console.log(response.data)
                        // response is {"leadID":"00Q5E00000BbJISUA3","model":"MAXIMA"} save leadID and model in localstorage
                        localStorage.setItem('leadID', response.data.leadID);
                        localStorage.setItem('model', response.data.model);
                        localStorage.setItem('name', response.data.name);

                        this.$emit('showUserTypo', response.data);

                    }
                    else{
                        this.$emit('showUserTypo', this.mobile.trim());
                    }

                    // if (response.data.length > 0) {
                    //     // User exists
                    //     this.$emit('showUserTypo', this.mobile);
                    // } else {
                    //     // User does not exist
                    //     this.$emit('showUserDoesNotExist', this.mobile);
                    // }
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    this.$emit('showUserTypo', this.mobile.trim());

                    console.log(error);
                })

                // let config = {
                //     method: 'get',
                //     maxBodyLength: Infinity,
                //     url: 'https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/search/?phone=971561499114',
                //     headers: { }
                //  };

                //     axios.request(config)
                //     .then((response) => {
                //          console.log(JSON.stringify(response.data));
                //     })
                //     .catch((error) => {
                //          console.log(error);
                //     });

                // Check if user exists
                // axios orgin

                // axios.get('https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/search/?phone='+this.mobile, {
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Accept': 'application/json',
                //         'Access-Control-Allow-Origin': '*',
                //         'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                //         'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                //     }
                // }).then((response) => {
                //     console.log(response.data);
                //     // if (response.data.length > 0) {
                //     //     // User exists
                //     //     this.$emit('showUserTypo', this.mobile);
                //     // } else {
                //     //     // User does not exist
                //     //     this.$emit('showUserDoesNotExist', this.mobile);
                //     // }
                // }).catch((error) => {
                //     console.log(error);
                // });

                // this.$emit('showUserTypo', this.mobile);
            } else {
                // Handle invalid input

            }
        },
    }
}
</script>

<style>

.error{
    border: 1px solid red!important;
    background-color: #fffcfc!important;

}
.error-message{
    font-size: 14px;
    position: relative;
    color: #000;
    background-color: #f5e7ea;
    line-height: 1.714;
    margin-top: 8px;
    padding: 16px 20px 16px 20px;
    border-radius: 4px;
}
.loader {
    zoom:.4;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 80%;
    border: 8px solid #0000;
    border-right-color: #fff;
    position: relative;
    animation: l24 1s infinite linear;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 80%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loader:after {
    animation-duration: 4s;
  }
  @keyframes l24 {
    100% {transform: rotate(1turn)}
  }
  .arTypo .txtMuted{
    direction: rtl;
  }
  .arTypo .heroInputArea{
    text-align: right;
  }
  .closeBtn{
    width: 2.3em;
    height: 2em;
    border: none;
    background: none;
    cursor: pointer;
    margin-left: auto;
  }

</style>
