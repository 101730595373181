<template>
  <div>
    <div class="appWrapper appTopHeader" ref="appHeader" style="display: flex;
    align-items: center;">
      <div class="registerInterest" @click="InterestShow" v-if="this.country == 'ksa'">{{ lang === 'en' ? 'Register Interest' : 'سجّل اهتمامك'}}</div>

      <RegisterInterest
      :langTypo="lang"
      :countryCode="country"
      v-if="this.hideInterest"
      @handleRegister="handleRegister"

      />





      <div class="areaheader" v-if="lang === 'en'">
        <label >Defy Ordinary</label>
        <h1>Start your Nissan Journey</h1>
        <p class="txtMuted">
          Find your perfect Nissan and explore our wide and exciting range of vehicles.<br>
           Select one of the models below to start your journey.
        </p>
      </div>

      <div class="areaheader" v-else>
        <label>تحدَّ المألوف</label>
        <h1>إكتشف سيارات نيسان</h1>
        <p class="txtMuted" dir="rtl">
          اكتشف سيارة نيسان المثالية لك و استعرض مجموعتنا الواسعة و المثيرة.
          اختر واحدة من الطرازات المذكورة أدناه لتبدأ رحلتك.<br>
        </p>
      </div>
      <div style="padding:20px; display: none;">
        <button type="button" @click="logout" style="border:0; background:transparent; cursor:pointer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="50px" width="50px" version="1.1" id="Capa_1" viewBox="0 0 471.2 471.2" xml:space="preserve">
            <g>
              <g>
                <path d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5    s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5    S235.019,444.2,227.619,444.2z"/>
                <path d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5    s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8    C455.319,239.9,455.319,231.3,450.019,226.1z"/>
              </g>
            </g>
            </svg>

        </button>
      </div>
    </div>

    <div style="padding:30px">
      <div class="areaheader" v-if="isLoading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div class="vehicleLineup"
      v-if="!isLoading"
      :dir="lang === 'ar' ? 'rtl' : 'ltr'"
      :class="{ 'ar-lineup': lang === 'ar' }"
      >
        <div
        class="item"
        v-for="(vehicle, i) in vehicleModelList"
          :key="i"


        >

              <div class="">
                <div class="vehicle-image" style="flex-basis: 59px;">
                  <a :href="'/' + country + '/'+ lang +'/vehicle/' + vehicle.name">
                    <img :src="vehicle.image"/>
                  </a>
                </div>

                <div class="vehicle-details">
                  <div class="vehicle-link" style="min-height: 107px;">
                    <div class="header-group no-chevron">
                      <h3 class="car-title">
                        <a class="title-link">
                          {{  lang === 'en' ? vehicle.name : vehicle.name_ar}}
                        </a>
                      </h3>
                    </div>

                    <div class="starting-price">
                        <div class="startingprice">
                          <span  v-if="lang === 'en'">Starting price</span>
                          <span v-else>ابتداءً من</span>

                        </div>
                        <div class="price priceBox">
                          <div class="wrapperPrice">
                                <div v-if="lang === 'ar'" class="wrapperPrice holder" v-html="getCurrencySymbol(vehicle.price)"></div>
                                <div class="wrapperPrice" v-else>{{ vehicle.price }}</div>
                            </div>

                          <div v-if="disclaimer !== ''" @click="toggleDisclaimer(i)" class="disclaimer"><img src="@/assets/img/disc.png" /></div>

                            <div v-if="!vehicle.id" class="disTooltip"

                            >
                              <span class="closeIcon" @click="closeDisclaimer(i)"><i>X</i></span>
                            <p>{{ disclaimer }}</p>
                          </div>

                      </div>



                    </div>
                  </div>
                  <div class="cta-section" style="min-height: 116px;">
                    <a class="iconNissan primary-cta" :href="'/' + country + '/'+ lang +'/vehicle/' + vehicle.name" @click="addBtntagging('Explore', currentBaseUrl+'/vehicle/' + vehicle.name, vehicle.name, vehicle.code)">
                      <span v-if="lang === 'en'">Explore</span>
                      <span v-else>إكتشف</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="lang === 'ar' && vehicle.cta_1_url !== null ? vehicle.cta_1_url.replace('en.', 'ar.') : vehicle.cta_1_url"  @click="addBtntagging(vehicle.cta_1, vehicle.cta_1_url, vehicle.name, vehicle.code)">
                      <span>{{ lang === 'en' ? vehicle.cta_1 : "اكتشف المزايا" }}</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="lang === 'ar' && vehicle.cta_2_url !== null ? vehicle.cta_2_url.replace('/en', '/ar') : vehicle.cta_2_url"  @click="addBtntagging(vehicle.cta_2, vehicle.cta_2_url, vehicle.name, vehicle.code)">
                      <span>{{ lang === 'en' ? vehicle.cta_2 : "تخصيص" }}</span>

                    </a>
                  </div>
                </div>
              </div>
        </div>

        <!-- <div>{{direction}}</div> -->

        <div class="arrowWrapper" v-if="arrowWrapper">
            <div class="arrowContainer">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
            </div>
          </div>

      </div>
    </div>


    <div v-if="checkLocal()">
    <WelcomeContact @showUserTypo="handleUserTypo" v-if="WelcomeContact" :langTypo="lang" :countryCode="country" />
    <ExcistUser v-if="ExcistUser" :langTypo="lang" :countryCode="country" />
    <NewUser v-if="NewUser" :selectedMobile="selectedNumber"  @closePopup="closePopup" :langTypo="lang" :countryCode="country"/>
  </div>


  </div>

</template>

<script>
import axios from "axios";
import { checkLogin, getGradeData } from '../components/constants/functions.js';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import adobeAnalytics from "@/plugin/analytics";

import WelcomeContact from "../components/modals/welcomeContact.vue";
import ExcistUser from "../components/modals/excistUser.vue";
import NewUser from "../components/modals/newUser.vue";
import RegisterInterest from "../components/modals/registerInterest.vue";

export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {
    VueperSlides, VueperSlide, WelcomeContact,ExcistUser,NewUser,RegisterInterest
  },

  data() {
    return {
      hideInterest: false,
      WelcomeContact: false,
      ExcistUser: false,
      NewUser: false,
      selectedNumber: "123",

      lang:"en",
      country:"az",

      currencySymbols: {
        "QR": "ريال",
        "KSA": "ر.س",
        "AED":"درهم"

      },

      arrowWrapper: true,
      currentBaseUrl: window.location.origin,
      colorsIn: false,
      selectedvehicleModel: null,
      threesixtyStatus: true,
      selectedColor: null,
      vehicleFoundStatus: false,
      vehicleModelList: [],
      loaded:false,
      selectedVehicleModelName: null,
      selectedVehicleId:null,
      choosedVehicle: '',
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},
      vehicles: {},


      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: 18,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: null,

      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,

      spotWrapper: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      imageColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],

      itemsPerRow: 4,
      currentRow: 0,
      isLoggedIn: false,
      v_id: 0,
      disclaimerBox: false,
      disclaimer: "",
      startY: 0,
      direction: "",
    };
  },
  mounted: function () {



    this.loadDisclaimerText();

    this.country =  this.$route.params.country
    this.lang =  this.$route.params.lang

    //console.log(this.country,"country");

    // set lang in local stroage
    // if (!localStorage.getItem("language")){
      localStorage.setItem('language',this.lang)
      localStorage.setItem('country',this.country)

    // }


    this.$nextTick(function () {



      const login = checkLogin();
      if (login){
        this.isLoggedIn = login;
      } else {
        //this.$router.push(`/${this.country}/${this.lang}/login`);
        this.$router.push(`/login`);
      }


      if (this.selectedVehicleModelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;
      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 7000);




            // const toolbar = this.$refs.appHeader
            // const sticky = toolbar.offsetTop;

            // function stickyEditorToolbar() {
            //     if (window.pageYOffset >= sticky) {
            //         toolbar.classList.add("sticky");
            //     } else {
            //         toolbar.classList.remove("sticky");
            //     }


            // }



    });




    //window.addEventListener('scroll', this.handleScroll)









    this.$el.addEventListener("click", this.onClick);
  },
  updated: function () { },
  computed: {
    rows() {
      const rowCount = Math.ceil(this.vehicleModelList.length / this.itemsPerRow);
      return Array.from({ length: rowCount }, (_, index) => index);
    },
    maxRow() {
      return this.rows.length - 1;
    },
  },
  created() {
    this.getVehicles();
  },
  methods: {

    handleRegister() {
      this.hideInterest = false
    },

    InterestShow(){
      this.hideInterest =true
    },

    async loadDisclaimerText() {
      try {
        const response = await axios.get("/disclaimer.json");
        //console.log(response,"response");
        const countryCode = this.country; // Example country code
        this.disclaimer = response.data[countryCode][this.lang];
      } catch (error) {
        console.error("Error loading disclaimer text:", error);
      }
    },

    getCurrencySymbol(val) {

      for (var key in this.currencySymbols) {
        if(val){

        val = val?.replace(new RegExp(key, "g"), `<span class="wrapperPrice getCurrencySymbol">${this.currencySymbols[key]}</span>`);}
      }
      return val;
      },

    logout(){
      localStorage.removeItem('noPopup');
      localStorage.removeItem('name');
      localStorage.removeItem('leadID');
      localStorage.removeItem('model');

      location.href= `/${this.country}/${this.lang}`;

    },
    closePopup(param){

      if(param == "close"){
        // alert()
        this.ExcistUser = false;
        this.WelcomeContact = false
        localStorage.setItem('noPopup', true);
      localStorage.removeItem('name');
      localStorage.removeItem('leadID');
      localStorage.removeItem('model');

      }
    },
    handleUserTypo(param) {

      console.log(param ,  typeof(param), typeof(param) == "object" )
      // alert(param)

      if(typeof(param) !== "object"){
        this.selectedNumber = param;
        this.ExcistUser = false;
        this.NewUser = true
      }else{

        this.ExcistUser = true;
        this.WelcomeContact = false
      }

      // if(mobile == this.demoNumber){
      //   this.ExcistUser = true;
      //   this.WelcomeContact = false
      // }else{
      //   this.ExcistUser = false;
      //   this.NewUser = true
      // }


    },




    handleScroll(){
            const toolbar = this.$refs.appHeader
            const sticky = toolbar.offsetTop;
            var scrollY = window.scrollY;getVehicles
            const off = window.pageYOffset

            if (off >= sticky) {
                  toolbar.classList.add("sticky");

                  this.arrowWrapper = false
                  //this.direction = 'Now going down...';
                } else {
                  toolbar.classList.remove("sticky");
                  this.arrowWrapper = true
                  //this.direction = 'Now going up!';
                }

            this.startY = scrollY;
    },
    toggleDisclaimer(i){

      this.vehicleModelList[i].id = !this.vehicleModelList[i].id;


    },
    closeDisclaimer(i){
      this.vehicleModelList[i].id = !this.vehicleModelList[i].id;
    },
    addBtntagging(selectedCta, url, vehicle_name, vehicleid) {
      let event_happened = selectedCta;

      let event_126 = "";
      let events = '';

      let eVar14 = event_happened + "|" + url

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
    },
    toggleColorBox() {
      if (this.colorsIn == true) {
        this.colorsIn = false;
      } else{
        this.colorsIn = true;
      }
    },

    checkLocal(){
      if(localStorage.getItem('noPopup') == null){
        return true
      }else{
        return false
      }
    },

    async getVehicles() {
      this.isLoading = true;
      const country_code = localStorage.getItem("country");
      try {
        const result = await axios.get(this.$apibaseurl + country_code+"/vehicles");
        this.vehicleModelList = result.data.data;

        console.log(this.vehicleModelList,"list");

        let event_happened = "Home";
        let event_126 = "";
        let events = '';
        let eVar14 = event_happened + "|" + this.currentBaseUrl
        adobeAnalytics(
        "R",
        "",
        "",
        "",
        "",
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },

    async getVehicleModelData(vehicleModelName) {
      const vehicleModelData = {};
      try {
        const result = await axios.get(this.$apibaseurl + "api/model/" + vehicleModelName);

        if (result.data.grades.length > 0) {
          const colorCode = 0;
          const gradeDataResponse = getGradeData(result.data.grades[0], 0, colorCode, 0, 0);
          for (const key in gradeDataResponse) {
            if (gradeDataResponse.hasOwnProperty(key)) {
              vehicleModelData[key] = gradeDataResponse[key];
            }
          }
          vehicleModelData.threesixtyStatus = true;
          vehicleModelData.vehicleFoundStatus = true;
        }
        return vehicleModelData;

      } catch (error) {
        console.log(error)
        return error;
      }
    },

    getRowVehicles(row) {
      const startIndex = row * this.itemsPerRow;
      const endIndex = startIndex + this.itemsPerRow;
      const rowVehicles = this.vehicleModelList.slice(startIndex, endIndex);

      let id = endIndex+1;
      while (rowVehicles.length < this.itemsPerRow) {
        rowVehicles.push({ id: id++ });
      }

      return this.vehicleModelList;
    },

    prevRow() {
      if (this.currentRow > 0) {
        this.currentRow--;
      }
    },

    nextRow() {
      if (this.currentRow < this.maxRow) {
        this.currentRow++;
      }
    },
  },

  watch: {},
};

</script>


<style>

.vueperslides__bullets--outside {
  margin-top: -50px !important;
}

.vueperslides__arrow svg {
  width: 5em;
}
.vueperslides__arrows{
  opacity: 0.5;
}
.vueperslides__arrow{
  transform: none;
  top:-250px;
}
.vehicle-details{
  padding-left: 50px;
}
.vehicle-link{
  margin-top: 0px;
}
.price{
  margin: 0;
  font: 70px/90px "Nissan Light","Nissan Regular",Verdana,Arial,sans-serif,"Noto Sans Arabic", sans-serif;
}

.starting-price{
  margin-bottom: 15px;
}

.content {
  -webkit-transition: all,1.2s,ease-out;
  -moz-transition: all,1.2s,ease-out;
  transition: all,1.2s,ease-out;
  visibility: visible;
  max-height: 500px;
  opacity: 1;
}

 img {
  border: 0;
  width: 720px;
  /* max-width: 100%; */
}

.primary-cta {
  padding: 40px 0;
  display: block;
  margin: 0;

  border: 0;
  text-decoration: none;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
}

.primary-cta:after {
  font-size: 2rem;
  content: "";
  color: #000;
  top: 50%;
  overflow: hidden;
  position: relative;
  padding-left: 10px;
  padding-top:30px;
  right: 0;
  margin-top: 0;
  display: inline-block;
  display: none;
}

.car-title, .car-title a {
    color: #000;
    text-decoration: none;
    font-size: 60px;
    padding-bottom: 25px;
}

.car-title {
    text-transform: uppercase;
    margin: 0 0 5px;
}

span {
  font-size: 45px;
}

.startingprice span{
  font-size: 40px;
}


.image-row-relative {
  position: relative;
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 100px;
  background-color: #000;
  color: white;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-buttons.left {
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.navigation-buttons.right {
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.navigation-buttons i {
  line-height: 80px;
  font-size: 24px;
}

.image-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.image-block {
  flex: 1;
  margin: 10px;
}

.custom-div {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}

.areaheader {
  text-align: center;
  margin-bottom: 6.5%;
  margin-top: 6.5%;
}

.areaheader label {
  font-weight: bold;
  font-size: 3em;
}

.areaheader h1 {
  font-weight: lighter;
  font-size: 100px;
}

.disabled {
  background-color: whitesmoke;
  cursor: not-allowed;
}


.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}

@media (min-height: 810px) {
  .panIcon .panning img{
    bottom: -55px;
  }
}

.loading{
  width: 100%;
  display: flex;

}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  margin: auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vehicleLineup.ar-lineup .vehicle-details{
  padding-left: 0px !important;
    padding-right: 17px;
}
.vehicleLineup.ar-lineup .vehicle-details .disTooltip{
  left: -14px;
}
.vehicleLineup.ar-lineup .vehicle-details .disclaimer{
  padding-right: 5px;
}
.vehicleLineup.ar-lineup .priceBox{
  font-weight: 300 !important;
  font-size: 20px !important
}
.vehicleLineup.ar-lineup .arrowContainer{
  left: 25px;
}
.appTopHeader{
  justify-content: center;
}
/* test */
.registerInterest{
     transition: all 0.3s cubic-bezier(0.77, 0, 0.18, 1);
    cursor: pointer;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0px;
    font-family: 'Nissan Bold', "Noto Sans Arabic", sans-serif;
    height: auto !important;
    text-transform: uppercase;
    border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000;
    padding: 10px 11px;
    position: absolute;
    right: 30px;
    top: auto;
}


</style>
