<template>
  <div id="app" >

    <router-view></router-view>

  </div>
</template>

<script>
import '@/assets/base.css';
import '@/assets/scss/main.scss';

export default {
  name: 'App',
}
</script>

<style>
</style>
