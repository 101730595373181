<template>
    <div class="modalWrapper">
        <div class="modalContainer">
            <div class="areaheader"
            :class="{ 'arTypo': langTypo === 'ar' }"
            >
                <h1>{{ langTypo === 'en' ? "Start your Nissan Journey" : 'ابدأ رحلتك مع نيسان'}}</h1>
                <p class="txtMuted">
                    {{ langTypo === 'en' ? " Please enter your phone number so we can personalize your experience." : 'الرجاء إدخال رقم هاتفك لكي نتمكن من تخصيص تجربتك بشكل أفضل.'}}

                </p>

                <form class="heroForm" @submit.prevent="checkUser">
                    <div class="form-group heroForm">
                        <input type="text" class="heroInputArea text" :class="error?'error':''" id="mobile" name="mobile"
                            :placeholder="placeholderText"
                            required="required" v-model="mobile" :disabled="loading" v-on:focus="onfocus" v-on:blur="onblur"
                            @input="updateInput"
                            />
                            <div v-if="error" class="error-message">
                                {{ countryErrorMessages[countryCode.toUpperCase()] }}
                            </div>
                    </div>

                    <button v-if="loading" class="formNextBtn" type="button" disabled>
                        <div class="loader"></div>
                    </button>
                    <button v-else class="formNextBtn" type="submit">{{ langTypo === 'en' ? "NEXT" : 'التالي'}}</button>

                </form>

            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    // emits: ['showNextComponent'],
    data(){
        return {
            loading: false,
            mobile: '',
            error: false,
            validate: false,

            lang:"en",
            country:"az",

            countryPatterns: {
                'AE': /^9715(0|4|6|5|2|8)\d{7}$/,
                'AZ': /^9715(0|4|6|5|2|8)\d{7}$/,
                'QA': /^974\d{8}$/,
                'BH': /^973\d{8}$/
                // Add more patterns for other countries as needed
            },
            countryErrorMessages: {
                'AE': 'Please provide a valid phone number, ex: 971501234567',
                'AZ': 'Please provide a valid phone number, ex: 971501234567',
                'QA': 'Please provide a valid phone number, ex: 97412345678',
                'BH': 'Please provide a valid phone number, ex: 97312345678'
                // Add more error messages for other countries as needed
            }
        }
    },
    props: {
        langTypo: String,
        countryCode: String
  },
  computed: {
        placeholderText() {
            switch (this.countryCode.toUpperCase()) {
                case 'AE':
                    if(this.langTypo == "en"){
                        return 'Please provide a valid phone number, ex: 971501234567';
                    }else{
                        return 'يرجى إدخال رقم هاتف صحيح، مثال:971501234567';
                    }
                    case 'AZ':
                    if(this.langTypo == "en"){
                        return 'Please provide a valid phone number, ex: 971501234567';
                    }else{
                        return 'يرجى إدخال رقم هاتف صحيح، مثال:971501234567';
                    }

                case 'QA':
                if(this.langTypo == "en"){
                    return 'Please provide a valid phone number, ex: 97412345678';
                    }else{
                        return 'يرجى إدخال رقم هاتف صحيح، مثال:97412345678';
                    }

                case 'BH':
                if(this.langTypo == "en"){
                    return 'Please provide a valid phone number, ex: 97312345678';
                    }else{
                        return 'يرجى إدخال رقم هاتف صحيح، مثال:97312345678';
                    }

                default:
                    return 'Please provide a valid phone number';
            }
        }
    },
    // onmounted clear localstorage
    mounted(){
        this.mobile = '';
        // localStorage.clear();
    },
    methods: {
        updateInput(e) {
            if (this.validate && this.countryPatterns[this.countryCode.toUpperCase()]) {
                if (e.target.value.match(this.countryPatterns[this.countryCode.toUpperCase()])) {
                    this.error = false;
                    console.log("true");
                } else {
                    this.error = true;
                    console.log("false");
                }
            }


        },
        onblur() {
            // e.target.placeholder = 'Enter your phone number';
            console.log("onblur")
        },
        onfocus(e) {
            // e.target.placeholder = '';
            console.log("onfocus")
        },
      async  checkUser(e) {
            if (this.mobile.trim()) {

                if (!this.mobile.match(this.countryPatterns[this.countryCode.toUpperCase()])) {
                this.error = true;
                this.validate = true;
                return 0;
            }

                this.loading = true
                const result = axios.get(this.$apibaseurl + "salesforce/search?phone="+this.mobile+"&random="+Math.ceil(Math.random()*1000000)).then((response) => {
                    console.log(response.data);

                    // if resppnse cpde 200
                    console.log("response.", response)

                    if(response.status == 200){
                        console.log(response.data)
                        // response is {"leadID":"00Q5E00000BbJISUA3","model":"MAXIMA"} save leadID and model in localstorage
                        localStorage.setItem('leadID', response.data.leadID);
                        localStorage.setItem('model', response.data.model);
                        localStorage.setItem('name', response.data.name);

                        this.$emit('showUserTypo', response.data);

                    }
                    else{
                        this.$emit('showUserTypo', this.mobile.trim());
                    }

                    // if (response.data.length > 0) {
                    //     // User exists
                    //     this.$emit('showUserTypo', this.mobile);
                    // } else {
                    //     // User does not exist
                    //     this.$emit('showUserDoesNotExist', this.mobile);
                    // }
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    this.$emit('showUserTypo', this.mobile.trim());

                    console.log(error);
                })

                // let config = {
                //     method: 'get',
                //     maxBodyLength: Infinity,
                //     url: 'https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/search/?phone=971561499114',
                //     headers: { }
                //  };

                //     axios.request(config)
                //     .then((response) => {
                //          console.log(JSON.stringify(response.data));
                //     })
                //     .catch((error) => {
                //          console.log(error);
                //     });

                // Check if user exists
                // axios orgin

                // axios.get('https://showroom.nissan-middleeast.com/backend/public/api/v1/salesforce/search/?phone='+this.mobile, {
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Accept': 'application/json',
                //         'Access-Control-Allow-Origin': '*',
                //         'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                //         'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                //     }
                // }).then((response) => {
                //     console.log(response.data);
                //     // if (response.data.length > 0) {
                //     //     // User exists
                //     //     this.$emit('showUserTypo', this.mobile);
                //     // } else {
                //     //     // User does not exist
                //     //     this.$emit('showUserDoesNotExist', this.mobile);
                //     // }
                // }).catch((error) => {
                //     console.log(error);
                // });

                // this.$emit('showUserTypo', this.mobile);
            } else {
                // Handle invalid input

            }
        },
    }
}
</script>

<style>

.error{
    border: 1px solid red!important;
    background-color: #fffcfc!important;

}
.error-message{
    font-size: 14px;
    position: relative;
    color: #000;
    background-color: #f5e7ea;
    line-height: 1.714;
    margin-top: 8px;
    padding: 16px 20px 16px 20px;
    border-radius: 4px;
}
.loader {
    zoom:.4;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 80%;
    border: 8px solid #0000;
    border-right-color: #fff;
    position: relative;
    animation: l24 1s infinite linear;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 80%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loader:after {
    animation-duration: 4s;
  }
  @keyframes l24 {
    100% {transform: rotate(1turn)}
  }
  .arTypo .txtMuted{
    direction: rtl;
  }
  .arTypo .heroInputArea{
    text-align: right;
  }

</style>
