import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import { VueHammer } from 'vue2-hammer'
import VueThreeSixty from './components/I360Viewer.vue'
// import VueThreeSixty from 'vue-360'

Vue.use(VueHammer)
Vue.component("vue-three-sixty", VueThreeSixty)

// import 'vue-360/dist/css/style.css'
// Vue.use(VueThreeSixty)

// Vue.prototype.$apibaseurl = 'https://apps.nissansouthafrica.com/backend/';
// Vue.prototype.$apibaseurl = 'https://showroom.nissansouthafrica.com/backend/';
Vue.prototype.$apibaseurl = 'https://showroom.nissan-middleeast.com/backend/public/api/v1/';
// Vue.prototype.$apibaseurl = 'http://localhost:8000/';

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
